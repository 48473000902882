import React, { useEffect, useState } from 'react'
import {
  Button, Form, Modal, Steps,
} from 'antd';
import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import PrincipalInvestigator from '../../container/PrincipalInvestigator';
import CenterAndDemographics from '../../container/CenterAndDemographics';
import ResearchInterest from '../../container/ResearchInterest';
import {
  addPiResetAction, createPiAction, getPiFilterDataAction, updatePiAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import { getDatasetListDataAction } from '../../container/SearchResults/logic';
import { getDocProfileValuesAction } from '../../container/DoctorProfile/logic';
import { getPiQuesDataAction } from '../../container/ResearchInterest/logic';
import { getautosuggestionResetAction } from '../../container/AddProjectName/logic';
import { getCookie, setAnalytics, hasPermissionForAction } from '../../../utils';
import PIComplianceIssues from '../../container/PIComplianceIssues';
import PIMedicalLicense from '../../container/PIMedicalLicense';
import { updateUploadedPIAction } from '../../container/UploadSponsorsList/logic';

export default function AddInvestigator({
  setDefaultValues, analysisId, flag, setModal, setsearchPi, setEditModal, setAddPiManually, piObj, type, viewedFile,
}) {
  const defaultValues = useSelector((state) => state.docProfile);
  const dispatch = useDispatch();
  const adminType = getCookie('permissions')
  const updatePiResponse = useSelector((store) => store.updatePiResponse);
  const createPiResponse = useSelector((store) => store.createPiResponse);
  const { query } = parse(location.search)
  const defaultPi = { primaryCenter: {}, other_centers: [], primaryCenterCountry: '' };
  const defaultCD = {};
  const defaultCompliance = {}
  const defaultCDD = {}
  const defaultMedicalLicense = {}
  const profile_responses = {}

  const [alertModalForNpiID, setAlertModalForNpiID] = useState(false)
  const [warningFlag, setWarningFlag] = useState(true)
  const [jumpToPage, setJumpToPage] = useState(0)
  const [flagForApiCall, setFlagForApiCall] = useState('')

  if (defaultValues?.data?.profile_responses) {
    defaultValues.data.profile_responses.forEach((item) => {
      profile_responses[item.id] = item?.response && item?.response[0];
    })
  }

  const permission = getCookie('permissions')
  let configRequired
  if (permission) {
    if (permission.includes('super_admin')) {
      configRequired = {
        0: ['piName', 'piLastName', 'telephoneNo', 'email', 'primary_organization_id', 'surveyEmail', 'specialties'],
      }
    } else {
      configRequired = {
        0: ['piName', 'piLastName', 'telephoneNo', 'specialties', 'primary_organization_id'],
      }
    }
  }

  if (setDefaultValues) {
    defaultPi.pre_award_survey_outreach = { value: defaultValues?.data?.pre_award_survey_outreach, flag: false }
    defaultPi.degree = { value: defaultValues?.data?.degree, flag: false }
    defaultPi.piFullName = { value: defaultValues?.data?.name, flag: false }
    defaultPi.piName = { value: defaultValues?.data?.first_name, flag: false }
    defaultPi.piLastName = { value: defaultValues?.data?.last_name, flag: false }
    defaultPi.piMiddleName = { value: defaultValues?.data?.middle_name, flag: false }
    defaultPi.titleName = { value: defaultValues?.data?.title, flag: false }
    defaultPi.telephoneNo = { value: defaultValues?.data?.phone_number.toString(), flag: false }
    defaultPi.mobileNo = { value: defaultValues?.data?.mobile_number.toString(), flag: false }
    defaultPi.faxNo = { value: defaultValues?.data?.fax.toString(), flag: false }
    defaultPi.email = { value: defaultValues?.data?.primary_email.toString(), flag: false }
    defaultPi.surveyEmail = { value: defaultValues?.data?.survey_email.toString(), flag: false }
    defaultPi.other_email = { value: defaultValues?.data?.other_email.toString(), flag: false }
    defaultPi.specialties = { value: defaultValues?.data?.specialties, flag: false }
    defaultPi.secondary_specialties = { value: defaultValues?.data?.secondary_specialties, flag: false }
    defaultPi.profile_responses = { value: defaultValues?.data?.profile_responses, flag: false }
    defaultPi.therapeutic_interest = { value: defaultValues?.data?.therapeutic_interest, flag: false }
    defaultPi.last_onsite_visit_date = { value: defaultValues?.data?.last_onsite_visit_date || 0, flag: false }
    defaultPi.last_onsite_visit_date_ts = { value: defaultValues?.data?.last_onsite_visit_date_ts, flag: false }
    defaultPi.npi = { value: defaultValues?.data?.npi, flag: false }
    defaultPi.is_head_of_department = { value: defaultValues?.data?.is_head_of_department || false, flag: false }
    defaultPi.name_of_department = { value: defaultValues?.data?.name_of_department, flag: false }
    defaultPi.consent_type = { value: defaultValues?.data?.consent_type, flag: false }
    defaultPi.consent_text = { value: defaultValues?.data?.consent_text, flag: false }
    defaultPi.address_line1 = { value: defaultValues?.data?.address?.address_line1 || '', flag: false }
    defaultPi.address_line2 = { value: defaultValues?.data?.address?.address_line2 || '', flag: false }
    defaultPi.city = { value: defaultValues?.data?.address?.city || '', flag: false }
    defaultPi.country = { value: defaultValues?.data?.address?.country || '', flag: false }
    defaultPi.state = { value: defaultValues?.data?.address?.state || '', flag: false }
    defaultPi.zip = { value: defaultValues?.data?.address?.zip || '', flag: false }
    defaultPi.alliance_type = { value: defaultValues?.data?.alliance_type || '', flag: false }
    defaultPi.alliance_member = { value: defaultValues?.data?.alliance_member || '', flag: false }
    defaultPi.alliance_member_id = { value: defaultValues?.data?.alliance_member_id || '', flag: false }
    defaultPi.pxl_author_sims_ids = { value: defaultValues?.data?.pxl_author_sims_ids || [], flag: false }
    defaultPi.veeva_ready_flag_updated = { value: defaultValues?.data?.veeva_ready_flag_updated || '', flag: false }

    defaultPi.primaryCenter = { value: defaultValues?.data?.affiliated_site, flag: false }
    defaultPi.primary_organization_id = { value: defaultValues?.data?.primary_organization_id, flag: false }
    defaultPi.primaryCenterCountry = { value: defaultValues?.data?.primary_organization?.country, flag: false }
    defaultCD.year_of_birth = { value: defaultValues?.data?.year_of_birth, flag: false }
    defaultCD.gender = { value: defaultValues?.data?.gender, flag: false }
    defaultCD.ethnicity = { value: defaultValues?.data?.ethnicity, flag: false }
    defaultCD.languages_spoken = { value: defaultValues?.data?.languages_spoken || [], flag: false }
    defaultPi.other_centers = {
      value: defaultValues?.data?.other_centers.map((itm) => {
        return { key: itm.id, value: itm.name }
      }) || [],
      flag: false,
    }
    defaultCompliance.compliances = defaultValues?.data?.compliances || []
    defaultCDD.cdd_data = defaultValues?.data?.cdd_data || [{
      status: '',
      prevention_flag: '',
      date: '',
      comment: '',
    }]
    defaultMedicalLicense.medical_license = defaultValues?.data?.medical_license_data || []
  }
  if (type === 'UploadSponsor') {
    defaultPi.piName = piObj?.first_name ? piObj?.first_name : ''
    defaultPi.piLastName = piObj?.last_name ? piObj?.last_name : ''
    defaultPi.piMiddleName = piObj?.piMiddleName ? piObj?.piMiddleName : ''
    defaultPi.specialties = piObj?.specialties ? piObj?.specialties : []
    defaultPi.therapeutic_interest = piObj?.therapeutic_interest ? piObj?.therapeutic_interest : []
    defaultPi.email = piObj?.email?.length ? piObj?.email.join(',') : ''
    defaultPi.telephoneNo = piObj?.phone_number ? piObj?.phone_number.toString() : ''
    defaultPi.mobileNo = piObj?.mobile_number ? piObj?.mobile_number.toString() : ''
    defaultPi.faxNo = piObj?.fax ? piObj?.fax : ''
    defaultPi.surveyEmail = piObj?.surveyEmail?.length ? piObj?.surveyEmail.join(',') : ''
    defaultPi.other_email = piObj?.other_email?.length ? piObj?.other_email.join(',') : ''
    defaultPi.name_of_department = piObj.name_of_department ? piObj.name_of_department : ''
    defaultPi.npi = piObj.npi ? piObj.npi : ''
    defaultPi.address_line1 = piObj?.primary_organization?.address_line1 ? piObj?.primary_organization?.address_line1 : ''
    defaultPi.address_line2 = piObj?.primary_organization?.address_line2 ? piObj?.primary_organization?.address_line2 : ''
    defaultPi.city = piObj?.primary_organization?.city ? piObj?.primary_organization?.city : ''
    defaultPi.country = piObj?.primary_organization?.country ? piObj?.primary_organization?.country : ''
    defaultPi.state = piObj?.primary_organization?.state ? piObj?.primary_organization?.state : ''
    defaultPi.zip = piObj?.primary_organization?.zip ? piObj?.primary_organization?.zip : ''
  }
  const [principalInvestigator, setPrincipalInvestigator] = useState(defaultPi)
  const [center, setCenter] = useState(defaultCD)
  const [researchInterest, setresearchInterest] = useState({ ...profile_responses })
  const [complianceIssues, setComplianceIssues] = useState(defaultCompliance.compliances || [])
  const [cddData, setCddData] = useState(defaultCDD.cdd_data || [{
    status: '',
    prevention_flag: '',
    date: '',
    comment: '',
  }])
  const [medicalLicense, setMedicalLicense] = useState(defaultMedicalLicense.medical_license || [])
  const [addCenter, setAddCenter] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [addOtherCenter, setAddOtherCenter] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [isSimsIdVerified, setIsSimsIdVerified] = useState(true)
  const [simsId, setSimsId] = useState('')
  const [MadnatoryFieldsMissingModal, setMadnatoryFieldsMissingModal] = useState({ isOpen: false, step: -1 })
  const [principalInvestigatorForm] = Form.useForm();
  const [centerForm] = Form.useForm();

  const [primaryCenterCountry, setPrimaryCenterCountry] = useState(defaultValues?.data?.primary_organization?.country)
  const [disabledFlagForEthnicity, setDisabledFlagForEthnicity] = useState(false)

  const [countryForNpiNumber, setCountryForNpiNumber] = useState('')

  useEffect(() => {
    dispatch(getPiQuesDataAction())
    dispatch(getPiFilterDataAction())
  }, [])

  useEffect(() => {
    if (createPiResponse.flag && type === 'UploadSponsor') {
      dispatch(updateUploadedPIAction({
        params: {
          innoplexus_id: piObj.innoplexus_id,
        },
        body: {
          action: 'insert',
          selected_id: '',
          status: undefined,
          file_name: viewedFile?.file_name,
          add_req_iid: createPiResponse?.data?.data?.innoplexus_id,
          is_added_by_lcor: viewedFile?.list_source === 'LCOR' ? true : undefined,
          is_added_by_sponsor: viewedFile?.list_source === 'Sponsor' ? true : undefined,
        },
      }, {
        key: piObj.innoplexus_id,
      }))
      setModal(false)
    }
    if (createPiResponse.flag && flag === 'fromMainPage') {
      setModal(false)
      setAlertModal(false)
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: 'investigators',
        }),
      );
      dispatch(addPiResetAction())
    } else if (createPiResponse.flag && flag === 'savedAnalysis' && type !== 'UploadSponsor') {
      setsearchPi(false)
      setAddPiManually(false)
      dispatch(addPiResetAction())
    }
    if (updatePiResponse.flag && flag === 'fromEditPI') {
      setEditModal(false)
      setAlertModal(false)
      dispatch(getDocProfileValuesAction({ id: (parse(location.search).id), dataset: 'investigators' }));
    }
    if (updatePiResponse.error && updatePiResponse.data.status === 409) {
      setAlertModal(true)
    }
    if (createPiResponse.error && createPiResponse.data.status === 409) {
      setAlertModal(true)
    }
  }, [JSON.stringify(createPiResponse), JSON.stringify(updatePiResponse)])

  const getComplicanceData = () => {
    const compData = complianceIssues.map((item) => {
      return {
        fraud_misconduct_code: item.fraud_misconduct_code,
        compliance_issue_comment: item.compliance_issue_comment,
        compliance_issue_raised_dt: item.compliance_issue_raised_dt,
        compliance_issue_desc: item.compliance_issue_desc,
        no_trial_participation_flag: item.no_trial_participation_flag,
        review_status: item.review_status,
        valid_flag: item.valid_flag,
        review_date: item.review_date,
        reviewer_name: item.reviewer_name,
        reevaluation_comment: item.reevaluation_comment,
        unique_id: item.unique_id,
      }
    })
    return compData
  }

  const getMedicalLicenseDataEdit = () => {
    const data = []
    medicalLicense.map((itm) => {
      data.push({
        medical_license_num: { old: '', new: itm.medical_license_num, flag: true },
        medical_license_country_name: { old: '', new: itm.medical_license_country_name, flag: true },
        medical_license_province_cn: { old: '', new: itm.medical_license_province_cn, flag: true },
        medical_license_expiry_dt: { old: '', new: itm.medical_license_expiry_dt, flag: true },
      })
      return undefined
    })
    return data
  }

  const getFinalTimeStamp = (dd) => {
    let finalDate = 0
    if (dd === 0) {
      return 0
    }
    if (dd.includes('-')) {
      finalDate = new Date(dd).getTime() / 1000
    } else {
      const d = new Date(dd)
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) {
        month = `0${month}`;
      }
      if (day.length < 2) {
        day = `0${day}`;
      }

      finalDate = new Date([year, month, day].join('-')).getTime() / 1000
    }
    return finalDate
  }
  const onDoneClick = () => {
    const firstStep = !configRequired[0]?.every((itm) => {
      if (Array.isArray(principalInvestigator[itm])) {
        return principalInvestigator[itm].length > 0
      }
      return !!principalInvestigator[itm]
    })
    const secondStep = configRequired[1]?.every((itm) => {
      if (itm === 'primaryCenter') {
        return !center[itm]?.val;
      }
      return !center[itm]
    })
    const hasIssue = firstStep || secondStep
    if (hasIssue) {
      setMadnatoryFieldsMissingModal({ isOpen: true, step: firstStep ? 1 : 2 })
    } else
    if (countryForNpiNumber === 'United States' && warningFlag) {
      if ('npi' in principalInvestigator) {
        if (flag !== 'fromEditPI') {
          if (principalInvestigator.npi === '') {
            setAlertModalForNpiID(true)
            setCurrent(current);
            setFlagForApiCall('')
          } else {
            setAlertModalForNpiID(false)
            setWarningFlag(false)
            onSubmitClick()
            setFlagForApiCall('')
          }
        } else if (principalInvestigator.npi.value === '') {
          setAlertModalForNpiID(true)
          setCurrent(current);
          setFlagForApiCall('')
        } else {
          setAlertModalForNpiID(false)
          setWarningFlag(false)
          onSubmitClick()
          setFlagForApiCall('')
        }
      } else {
        setAlertModalForNpiID(true)
        setCurrent(current);
        setFlagForApiCall('')
      }
    } else {
      setFlagForApiCall('')
      let compliance = {}
      if (flag === 'fromEditPI') {
        if (complianceIssues.length > 0) {
          compliance = {
            old: false,
            new: true,
            flag: true,
          }
        } else {
          compliance = {
            old: false,
            new: false,
            flag: false,
          }
        }
      }

      const final = {
        ...principalInvestigator, ...center, ...researchInterest,
      }
      if (!setDefaultValues) {
        const getProfRespData = (resp) => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: [
                  resp[key] || '',
                ],
              })
            }
          })
          return responses
        }
        const temp = {
          name: final.piName.concat(' ', final.piMiddleName).concat(' ', final.piLastName),
          pre_award_survey_outreach: final?.pre_award_survey_outreach === undefined ? 'Yes' : final?.pre_award_survey_outreach,
          recent_site_id: final?.primary_center?.key,
          email: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
          primary_email: final.email,
          survey_email: transformStringToArray(final?.surveyEmail),
          other_email: final?.other_email?.split(/[,;]+/),
          phone_number: transformStringToArray(final?.telephoneNo),
          therapeutic_interest: final.therapeutic_interest,
          specialties: final?.specialties,
          primary_organization_id: final?.primary_organization_id,
          degree: final?.degree,
          title: final.titleName,
          first_name: final.piName,
          last_name: final.piLastName,
          middle_name: final.piMiddleName,
          mobile_number: transformStringToArray(final?.mobileNo),
          fax: final?.faxNo?.split(','),
          secondary_specialties: transformStringToArray(final.secondary_specialties),
          cv_link: '',
          gcp_certificate_link: '',
          year_of_birth: final?.year_of_birth,
          gender: final.gender,
          ethnicity: final.ethnicity,
          languages_spoken: final.languages_spoken,
          type: 'PI',
          profile_responses: getProfRespData(final),
          site_ids: final?.other_centers?.map((itm) => itm.key),
          last_onsite_visit_date: parseInt(new Date(final.last_onsite_visit_date).getTime() / 1000, 10) || 0,
          npi: final.npi ? final.npi.toString() : '',
          is_head_of_department: final.is_head_of_department,
          name_of_department: final.name_of_department,
          consent_type: final.consent_type,
          consent_text: final.consent_text,
          address: {
            address_line1: final.address_line1,
            address_line2: final.address_line2,
            city: final.city,
            state: final.state,
            country: final.country,
            zip: final.zip ? final.zip.toString() : '',
          },
          alliance_type: final.alliance_type,
          alliance_member_id: final.alliance_member_id,
          compliances: getComplicanceData(),
          cdd_data: cddData,
          medical_license_data: medicalLicense,
        }

        if (analysisId !== '' && type === 'UploadSponsor') {
          dispatch(createPiAction({
            params: {
              project_id: analysisId,
              force_update: true,
              sponsor_meta_iid: piObj.innoplexus_id,
              is_added_by_lcor: viewedFile?.list_source === 'LCOR' ? true : undefined,
              is_added_by_sponsor: viewedFile?.list_source === 'Sponsor' ? true : undefined,
            },
            body: {
              ...temp,
              file_name: viewedFile?.file_name,
            },
          }))
        } else if (analysisId) {
          dispatch(createPiAction({
            params: {
              project_id: analysisId,
              force_update: true,
              is_cfl: adminType.includes('cfl_access') ? true : undefined,
              is_lcor: adminType.includes('lcor_access') ? true : undefined,
            },
            body: temp,
          }))
        } else {
          dispatch(createPiAction({
            params: {
              force_update: true,
              duplicate_update: false,
            },
            body: temp,
          }))
        }
      } else {
        const getProfRespData = () => {
          const pr = [];
          const pr_all = defaultValues?.data?.profile_responses;
          const finalResp = { ...researchInterest }

          Object.keys(finalResp).forEach((k) => {
            const temp = {
              question_id: {
                old: Number(k),
                new: Number(k),
                flag: true,
              },
              text_response: {
                old: [''],
                new: [finalResp[k]],
                flag: true,
              },
            };
            const index = pr_all.findIndex(
              (item) => item.question_id.toString() === k.toString(),
            );
            if (index < 0) {
              pr.push(temp);
            } else {
              pr.push({
                question_id: {
                  old: Number(k),
                  new: Number(k),
                  flag: true,
                },
                text_response: {
                  old: pr_all[index].response,
                  new: [final[k]],
                  flag: true,
                },
              });
            }
          });
          return pr
        }
        const updateTemp = {
          name: {
            old: defaultPi.piFullName.value,
            new: final.piName.value.concat(' ', final.piMiddleName.value).concat(' ', final.piLastName.value),
            flag: final.piName.flag || final.piMiddleName.flag || final.piLastName.flag,
          },
          pre_award_survey_outreach: {
            old: defaultPi.pre_award_survey_outreach.value,
            new: final.pre_award_survey_outreach.value,
            flag: final.pre_award_survey_outreach.flag,
          },
          email: {
            old: defaultPi?.other_email ? transformStringToArray(`${defaultPi?.email}, ${defaultPi?.other_email}`) : transformStringToArray(`${defaultPi?.email}`),
            new: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
            flag: final.email.flag,
          },
          other_email: {
            old: defaultPi?.other_email?.value?.split(/[,;]+/),
            new: final?.other_email?.value?.split(/[,;]+/),
            flag: final.other_email.flag,
          },
          survey_email: {
            old: transformStringToArray(defaultPi?.surveyEmail?.value),
            new: transformStringToArray(final?.surveyEmail?.value),
            flag: final.surveyEmail.flag,
          },
          primary_email: {
            old: defaultPi.email.value,
            new: final.email.value,
            flag: final.email.flag,
          },
          phone_number: {
            old: transformStringToArray(defaultPi.telephoneNo.value),
            new: transformStringToArray(final?.telephoneNo.value),
            flag: final.telephoneNo.flag,
          },
          therapeutic_interest: {
            old: defaultPi.therapeutic_interest.value,
            new: final.therapeutic_interest.value,
            flag: final.therapeutic_interest.flag,
          },
          address: {
            address_line1: {
              old: defaultPi.address_line1.value,
              new: final.address_line1.value,
              flag: final.address_line1.flag,
            },
            address_line2: {
              old: defaultPi.address_line2.value,
              new: final.address_line2.value,
              flag: final.address_line2.flag,
            },
            city: {
              old: defaultPi.city.value,
              new: final.city.value,
              flag: final.city.flag,
            },
            state: {
              old: defaultPi.state.value,
              new: final.state.value,
              flag: final.state.flag,
            },
            country: {
              old: defaultPi.country.value,
              new: final.country.value,
              flag: final.country.flag,
            },
            zip: {
              old: defaultPi.zip.value,
              new: final.zip.value,
              flag: final.zip.flag,
            },
          },
          pxl_author_sims_ids: {
            old: defaultPi?.pxl_author_sims_ids.value,
            new: simsId === '' ? [] : [simsId],
            flag: simsId !== '',
          },
          veeva_ready_flag_updated: {
            old: defaultPi?.veeva_ready_flag_updated.value,
            new: final.veeva_ready_flag_updated.value,
            flag: final.veeva_ready_flag_updated.flag,
          },

          specialties: {
            old: defaultPi?.specialties.value,
            new: final?.specialties.value,
            flag: final.specialties.flag,
          },
          primary_organization_id: {
            old: defaultPi.primary_organization_id.value,
            new: final.primary_organization_id.value,
            flag: final.primary_organization_id.flag,
          },
          degree: {
            old: defaultPi.degree.value,
            new: final.degree.value,
            flag: final.degree.flag,
          },
          title: {
            old: defaultPi.titleName.value,
            new: final.titleName.value,
            flag: final.titleName.flag,
          },
          first_name: {
            old: defaultPi.piName.value,
            new: final.piName.value,
            flag: final.piName.flag,
          },
          middle_name: {
            old: defaultPi.piMiddleName.value,
            new: final.piMiddleName.value,
            flag: final.piMiddleName.flag,
          },
          last_name: {
            old: defaultPi.piLastName.value,
            new: final.piLastName.value,
            flag: final.piLastName.flag,
          },
          mobile_number: {
            old: transformStringToArray(defaultPi.mobileNo.value),
            new: transformStringToArray(final.mobileNo.value),
            flag: final.mobileNo.flag,
          },
          fax: {
            // old: transformStringToArray(defaultPi.faxNo.value, 'fax'),
            old: defaultPi?.faxNo?.value?.split(','),
            new: final?.faxNo?.value?.split(','),
            // new: transformStringToArray(final.faxNo.value, 'fax'),
            flag: final.faxNo.flag,
          },
          secondary_specialties: {
            old: transformStringToArray(defaultPi.secondary_specialties.value),
            new: transformStringToArray(final.secondary_specialties.value),
            flag: final.secondary_specialties.flag,
          },
          year_of_birth: {
            old: defaultCD.year_of_birth.value || '',
            new: final.year_of_birth.value || '',
            flag: final.year_of_birth.flag,
          },
          gender: {
            old: defaultCD.gender.value || '',
            new: final.gender.value || '',
            flag: final.gender.flag,
          },
          ethnicity: {
            old: defaultCD.ethnicity.value || '',
            new: final.ethnicity.value || '',
            flag: final.ethnicity.flag,
          },
          languages_spoken: {
            old: defaultCD.languages_spoken.value || [],
            new: final.languages_spoken.value || [],
            flag: final.languages_spoken.flag,
          },
          profile_responses: getProfRespData(),
          site_ids: {
            old: defaultPi?.other_centers?.value?.map((itm) => { return itm.key }),
            new: final?.other_centers?.value?.map((itm) => itm.key),
            flag: final.other_centers.flag,
          },
          last_onsite_visit_date: {
            old: defaultPi.last_onsite_visit_date_ts.value || 0,
            new: getFinalTimeStamp(final.last_onsite_visit_date.value) || 0,
            flag: final.last_onsite_visit_date.flag,
          },
          npi: {
            old: defaultPi.npi.value,
            new: final.npi.value,
            flag: final.npi.flag,
          },
          is_head_of_department: {
            old: defaultPi.is_head_of_department.value,
            new: final.is_head_of_department.value,
            flag: final.is_head_of_department.flag,
          },
          name_of_department: {
            old: defaultPi.name_of_department.value,
            new: final.name_of_department.value,
            flag: final.name_of_department.flag,
          },
          alliance_type: {
            old: defaultPi.alliance_type.value,
            new: final.alliance_type.value,
            flag: final.alliance_type.flag,
          },
          alliance_member_id: {
            old: defaultPi.alliance_member_id.value,
            new: final.alliance_member_id.value,
            flag: final.alliance_member_id.flag,
          },
          compliances: complianceIssues?.map((itm) => {
            return {
              no_trial_participation_flag: {
                old: '',
                new: itm.no_trial_participation_flag,
                flag: true,
              },
              fraud_misconduct_code: {
                old: '',
                new: itm.fraud_misconduct_code,
                flag: true,
              },
              compliance_issue_desc: {
                old: '',
                new: itm.compliance_issue_desc,
                flag: true,
              },
              compliance_issue_comment: {
                old: '',
                new: itm.compliance_issue_comment,
                flag: true,
              },
              compliance_issue_raised_dt: {
                old: '',
                new: itm.compliance_issue_raised_dt,
                flag: true,
              },
              compliance_issue_closed_dt: {
                old: '',
                new: itm.compliance_issue_closed_dt,
                flag: true,
              },
              review_status: {
                old: '',
                new: itm.review_status,
                flag: true,
              },
              valid_flag: {
                old: undefined,
                new: itm.valid_flag,
                flag: true,
              },
              review_date: {
                old: '',
                new: itm.review_date,
                flag: true,
              },
              reviewer_name: {
                old: '',
                new: itm.reviewer_name,
                flag: true,
              },
              reevaluation_comment: {
                old: '',
                new: itm.reevaluation_comment,
                flag: true,
              },
              delete_flag: {
                old: false,
                new: itm.delete_flag,
                flag: true,
              },
              unique_id: {
                old: '',
                new: itm.unique_id,
                flag: true,
              },
            }
          }),
          cdd_data: cddData?.map((itm) => {
            return {
              status: {
                old: '',
                new: itm.status,
                flag: true,
              },
              prevention_flag: {
                old: '',
                new: itm.prevention_flag,
                flag: true,
              },
              date: {
                old: '',
                new: itm.date,
                flag: true,
              },
              comment: {
                old: '',
                new: itm.comment,
                flag: true,
              },
              // unique_id: {
              //   old: '',
              //   new: itm.unique_id,
              //   flag: true,
              // },
            }
          }),
          medical_license_data: getMedicalLicenseDataEdit(),
          compliance,
          consent_type: {
            old: defaultPi.consent_type.value,
            new: final.consent_type.value,
            flag: final.consent_type.flag,
          },
          consent_text: {
            old: defaultPi.consent_text.value,
            new: final.consent_text.value,
            flag: final.consent_text.flag,
          },
        }
        dispatch(updatePiAction({
          params: {
            pi_id: defaultValues?.data?.id,
            direct_edit: true,
            duplicate_update: false,
          },
          body: updateTemp,
        }))
      }
      const params = {
        pi_id: defaultValues?.data?.id,
      }
      setAnalytics('editInvestigators', 'data', params)
    }
  }

  const onSubmitClick = () => {
    const firstStep = !configRequired[0]?.every((itm) => {
      if (Array.isArray(principalInvestigator[itm])) {
        return principalInvestigator[itm].length > 0
      }
      return !!principalInvestigator[itm]
    })
    const secondStep = configRequired[1]?.every((itm) => {
      if (itm === 'primaryCenter') {
        return !center[itm]?.val;
      }
      return !center[itm]
    })
    const hasIssue = firstStep || secondStep
    if (hasIssue) {
      setMadnatoryFieldsMissingModal({ isOpen: true, step: firstStep ? 1 : 2 })
    } else {
      let compliance = {}
      if (flag === 'fromEditPI') {
        if (complianceIssues.length > 0) {
          compliance = {
            old: false,
            new: true,
            flag: true,
          }
        } else {
          compliance = {
            old: false,
            new: false,
            flag: false,
          }
        }
      }

      const final = {
        ...principalInvestigator, ...center, ...researchInterest,
      }
      if (!setDefaultValues) {
        const getProfRespData = (resp) => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: [
                  resp[key] || '',
                ],
              })
            }
          })
          return responses
        }
        const temp = {
          name: final.piName.concat(' ', final.piMiddleName).concat(' ', final.piLastName),
          pre_award_survey_outreach: final?.pre_award_survey_outreach === undefined ? 'Yes' : final?.pre_award_survey_outreach,
          recent_site_id: final?.primary_center?.key,
          email: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
          primary_email: final.email,
          survey_email: transformStringToArray(final?.surveyEmail),
          other_email: final?.other_email?.split(/[,;]+/),
          phone_number: transformStringToArray(final?.telephoneNo),
          therapeutic_interest: final.therapeutic_interest,
          specialties: final?.specialties,
          primary_organization_id: final?.primary_organization_id,
          degree: final?.degree,
          title: final.titleName,
          first_name: final.piName,
          last_name: final.piLastName,
          middle_name: final.piMiddleName,
          mobile_number: transformStringToArray(final?.mobileNo),
          fax: final?.faxNo?.split(','),
          secondary_specialties: transformStringToArray(final.secondary_specialties),
          cv_link: '',
          gcp_certificate_link: '',
          year_of_birth: final?.year_of_birth,
          gender: final.gender,
          ethnicity: final.ethnicity,
          languages_spoken: final.languages_spoken,
          type: 'PI',
          profile_responses: getProfRespData(final),
          site_ids: final?.other_centers?.map((itm) => itm.key),
          last_onsite_visit_date: parseInt(new Date(final.last_onsite_visit_date).getTime() / 1000, 10) || 0,
          npi: final.npi ? final.npi.toString() : '',
          is_head_of_department: final.is_head_of_department,
          name_of_department: final.name_of_department,
          consent_type: final.consent_type,
          consent_text: final.consent_text,
          address: {
            address_line1: final.address_line1,
            address_line2: final.address_line2,
            city: final.city,
            state: final.state,
            country: final.country,
            zip: final.zip ? final.zip.toString() : '',
          },
          alliance_type: final.alliance_type,
          alliance_member_id: final.alliance_member_id,
          compliances: getComplicanceData(),
          cdd_data: cddData.length && cddData[0]?.status !== '' ? cddData : [],
          medical_license_data: medicalLicense,
        }

        if (analysisId !== '' && type === 'UploadSponsor') {
          dispatch(createPiAction({
            params: {
              project_id: analysisId,
              force_update: true,
              sponsor_meta_iid: piObj.innoplexus_id,
              is_added_by_lcor: viewedFile?.list_source === 'LCOR' ? true : undefined,
              is_added_by_sponsor: viewedFile?.list_source === 'Sponsor' ? true : undefined,
            },
            body: {
              ...temp,
              file_name: viewedFile?.file_name,
            },
          }))
        } else if (analysisId) {
          dispatch(createPiAction({
            params: {
              project_id: analysisId,
              force_update: true,
              is_cfl: adminType.includes('cfl_access') ? true : undefined,
              is_lcor: adminType.includes('lcor_access') ? true : undefined,
            },
            body: temp,
          }))
        } else {
          dispatch(createPiAction({
            params: {
              force_update: true,
              duplicate_update: false,
            },
            body: temp,
          }))
        }
      } else {
        const getProfRespData = () => {
          const pr = [];
          const pr_all = defaultValues?.data?.profile_responses;
          const finalResp = { ...researchInterest }

          Object.keys(finalResp).forEach((k) => {
            const temp = {
              question_id: {
                old: Number(k),
                new: Number(k),
                flag: true,
              },
              text_response: {
                old: [''],
                new: [finalResp[k]],
                flag: true,
              },
            };
            const index = pr_all.findIndex(
              (item) => item.question_id.toString() === k.toString(),
            );
            if (index < 0) {
              pr.push(temp);
            } else {
              pr.push({
                question_id: {
                  old: Number(k),
                  new: Number(k),
                  flag: true,
                },
                text_response: {
                  old: pr_all[index].response,
                  new: [final[k]],
                  flag: true,
                },
              });
            }
          });
          return pr
        }
        const updateTemp = {
          name: {
            old: defaultPi.piFullName.value,
            new: final.piName.value.concat(' ', final.piMiddleName.value).concat(' ', final.piLastName.value),
            flag: final.piName.flag || final.piMiddleName.flag || final.piLastName.flag,
          },
          pre_award_survey_outreach: {
            old: defaultPi.pre_award_survey_outreach.value,
            new: final.pre_award_survey_outreach.value,
            flag: final.pre_award_survey_outreach.flag,
          },
          email: {
            old: defaultPi?.other_email ? transformStringToArray(`${defaultPi?.email}, ${defaultPi?.other_email}`) : transformStringToArray(`${defaultPi?.email}`),
            new: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
            flag: final.email.flag,
          },
          other_email: {
            old: defaultPi?.other_email?.value?.split(/[,;]+/),
            new: final?.other_email?.value?.split(/[,;]+/),
            flag: final.other_email.flag,
          },
          survey_email: {
            old: transformStringToArray(defaultPi?.surveyEmail?.value),
            new: transformStringToArray(final?.surveyEmail?.value),
            flag: final.surveyEmail.flag,
          },
          primary_email: {
            old: defaultPi.email.value,
            new: final.email.value,
            flag: final.email.flag,
          },
          phone_number: {
            old: transformStringToArray(defaultPi.telephoneNo.value),
            new: transformStringToArray(final?.telephoneNo.value),
            flag: final.telephoneNo.flag,
          },
          therapeutic_interest: {
            old: defaultPi.therapeutic_interest.value,
            new: final.therapeutic_interest.value,
            flag: final.therapeutic_interest.flag,
          },
          address: {
            address_line1: {
              old: defaultPi.address_line1.value,
              new: final.address_line1.value,
              flag: final.address_line1.flag,
            },
            address_line2: {
              old: defaultPi.address_line2.value,
              new: final.address_line2.value,
              flag: final.address_line2.flag,
            },
            city: {
              old: defaultPi.city.value,
              new: final.city.value,
              flag: final.city.flag,
            },
            state: {
              old: defaultPi.state.value,
              new: final.state.value,
              flag: final.state.flag,
            },
            country: {
              old: defaultPi.country.value,
              new: final.country.value,
              flag: final.country.flag,
            },
            zip: {
              old: defaultPi.zip.value,
              new: final.zip.value,
              flag: final.zip.flag,
            },
          },
          pxl_author_sims_ids: {
            old: defaultPi?.pxl_author_sims_ids.value,
            new: simsId === '' ? [] : [simsId],
            flag: simsId !== '',
          },
          veeva_ready_flag_updated: {
            old: defaultPi?.veeva_ready_flag_updated.value,
            new: final.veeva_ready_flag_updated.value,
            flag: final.veeva_ready_flag_updated.flag,
          },
          specialties: {
            old: defaultPi?.specialties.value,
            new: final?.specialties.value,
            flag: final.specialties.flag,
          },
          primary_organization_id: {
            old: defaultPi.primary_organization_id.value,
            new: final.primary_organization_id.value,
            flag: final.primary_organization_id.flag,
          },
          degree: {
            old: defaultPi.degree.value,
            new: final.degree.value,
            flag: final.degree.flag,
          },
          title: {
            old: defaultPi.titleName.value,
            new: final.titleName.value,
            flag: final.titleName.flag,
          },
          first_name: {
            old: defaultPi.piName.value,
            new: final.piName.value,
            flag: final.piName.flag,
          },
          middle_name: {
            old: defaultPi.piMiddleName.value,
            new: final.piMiddleName.value,
            flag: final.piMiddleName.flag,
          },
          last_name: {
            old: defaultPi.piLastName.value,
            new: final.piLastName.value,
            flag: final.piLastName.flag,
          },
          mobile_number: {
            old: transformStringToArray(defaultPi.mobileNo.value),
            new: transformStringToArray(final.mobileNo.value),
            flag: final.mobileNo.flag,
          },
          fax: {
            // old: transformStringToArray(defaultPi.faxNo.value, 'fax'),
            old: defaultPi?.faxNo?.value?.split(','),
            new: final?.faxNo?.value?.split(','),
            // new: transformStringToArray(final.faxNo.value, 'fax'),
            flag: final.faxNo.flag,
          },
          secondary_specialties: {
            old: transformStringToArray(defaultPi.secondary_specialties.value),
            new: transformStringToArray(final.secondary_specialties.value),
            flag: final.secondary_specialties.flag,
          },
          year_of_birth: {
            old: defaultCD.year_of_birth.value || '',
            new: final.year_of_birth.value || '',
            flag: final.year_of_birth.flag,
          },
          gender: {
            old: defaultCD.gender.value || '',
            new: final.gender.value || '',
            flag: final.gender.flag,
          },
          ethnicity: {
            old: defaultCD.ethnicity.value || '',
            new: final.ethnicity.value || '',
            flag: final.ethnicity.flag,
          },
          languages_spoken: {
            old: defaultCD.languages_spoken.value || [],
            new: final.languages_spoken.value || [],
            flag: final.languages_spoken.flag,
          },
          profile_responses: getProfRespData(),
          site_ids: {
            old: defaultPi?.other_centers?.value?.map((itm) => { return itm.key }),
            new: final?.other_centers?.value?.map((itm) => itm.key),
            flag: final.other_centers.flag,
          },
          last_onsite_visit_date: {
            old: defaultPi.last_onsite_visit_date_ts.value || 0,
            new: getFinalTimeStamp(final.last_onsite_visit_date.value) || 0,
            flag: final.last_onsite_visit_date.flag,
          },
          npi: {
            old: defaultPi.npi.value,
            new: final.npi.value,
            flag: final.npi.flag,
          },
          is_head_of_department: {
            old: defaultPi.is_head_of_department.value,
            new: final.is_head_of_department.value,
            flag: final.is_head_of_department.flag,
          },
          name_of_department: {
            old: defaultPi.name_of_department.value,
            new: final.name_of_department.value,
            flag: final.name_of_department.flag,
          },
          alliance_type: {
            old: defaultPi.alliance_type.value,
            new: final.alliance_type.value,
            flag: final.alliance_type.flag,
          },
          alliance_member_id: {
            old: defaultPi.alliance_member_id.value,
            new: final.alliance_member_id.value,
            flag: final.alliance_member_id.flag,
          },
          compliances: complianceIssues?.map((itm) => {
            return {
              no_trial_participation_flag: {
                old: '',
                new: itm.no_trial_participation_flag,
                flag: true,
              },
              fraud_misconduct_code: {
                old: '',
                new: itm.fraud_misconduct_code,
                flag: true,
              },
              compliance_issue_desc: {
                old: '',
                new: itm.compliance_issue_desc,
                flag: true,
              },
              compliance_issue_comment: {
                old: '',
                new: itm.compliance_issue_comment,
                flag: true,
              },
              compliance_issue_raised_dt: {
                old: '',
                new: itm.compliance_issue_raised_dt,
                flag: true,
              },
              compliance_issue_closed_dt: {
                old: '',
                new: itm.compliance_issue_closed_dt,
                flag: true,
              },
              review_status: {
                old: '',
                new: itm.review_status,
                flag: true,
              },
              valid_flag: {
                old: undefined,
                new: itm.valid_flag,
                flag: true,
              },
              review_date: {
                old: '',
                new: itm.review_date,
                flag: true,
              },
              reviewer_name: {
                old: '',
                new: itm.reviewer_name,
                flag: true,
              },
              reevaluation_comment: {
                old: '',
                new: itm.reevaluation_comment,
                flag: true,
              },
              delete_flag: {
                old: false,
                new: itm.delete_flag,
                flag: true,
              },
              unique_id: {
                old: '',
                new: itm.unique_id,
                flag: true,
              },
            }
          }),
          cdd_data: cddData?.map((itm) => {
            return {
              status: {
                old: '',
                new: itm.status,
                flag: true,
              },
              prevention_flag: {
                old: '',
                new: itm.prevention_flag,
                flag: true,
              },
              date: {
                old: '',
                new: itm.date,
                flag: true,
              },
              comment: {
                old: '',
                new: itm.comment,
                flag: true,
              },
              // unique_id: {
              //   old: '',
              //   new: itm.unique_id,
              //   flag: true,
              // },
            }
          }),
          medical_license_data: getMedicalLicenseDataEdit(),
          compliance,
          consent_type: {
            old: defaultPi.consent_type.value,
            new: final.consent_type.value,
            flag: final.consent_type.flag,
          },
          consent_text: {
            old: defaultPi.consent_text.value,
            new: final.consent_text.value,
            flag: final.consent_text.flag,
          },
        }
        dispatch(updatePiAction({
          params: {
            pi_id: defaultValues?.data?.id,
            direct_edit: true,
            duplicate_update: false,
          },
          body: updateTemp,
        }))
      }
      const params = {
        pi_id: defaultValues?.data?.id,
      }
      setAnalytics('editInvestigators', 'data', params)
    }
  }
  const { Step } = Steps;
  const piSteps = [
    {
      title: 'Profile information',
      content: <PrincipalInvestigator data={{
        principalInvestigator,
        setPrincipalInvestigator,
        principalInvestigatorForm,
        IsDropDownOpen: addCenter,
        SetDropDownOpen: setAddCenter,
        IsOtherDropDownOpen: addOtherCenter,
        SetOtherDropDownOpen: setAddOtherCenter,
        flag,
        setIsSimsIdVerified,
        simsId,
        setSimsId,
        setPrimaryCenterCountry,
        primaryCenterCountry,
        setDisabledFlagForEthnicity,
        setCountryForNpiNumber,
        countryForNpiNumber,
        setWarningFlag,
      }}
      />,
    },
    {
      title: 'Demographics',
      content: <CenterAndDemographics data={{
        center,
        setCenter,
        centerForm,
        IsDropDownOpen: addCenter,
        SetDropDownOpen: setAddCenter,
        IsOtherDropDownOpen: addOtherCenter,
        SetOtherDropDownOpen: setAddOtherCenter,
        flag,
        country: principalInvestigator.country,
        disabledFlagForEthnicity,
        primaryCenterCountry,
        setDisabledFlagForEthnicity,
      }}
      />,
    },
    {
      title: 'Research interests',
      content: <ResearchInterest data={{ researchInterest, setresearchInterest, flag }} />,
    },
    {
      title: 'Medical License',
      content: <PIMedicalLicense data={{ medicalLicense, setMedicalLicense, flag }} />,
    },
  ]
  const adminAccessSteps = [
    {
      title: 'Quality Issues',
      content: <PIComplianceIssues data={{
        complianceIssues, setComplianceIssues, cddData, setCddData, flag,
      }}
      />,
    },
  ]

  let steps
  if ((flag === 'fromEditPI' || flag === 'runAnalysis' || flag === 'savedAnalysis') && (getCookie('permissions').includes('sam_access')
    || getCookie('permissions').includes('sfl_access')
    || getCookie('permissions').includes('super_admin'))) {
    steps = [...piSteps, ...adminAccessSteps]
  } else if (flag === 'fromMainPage') {
    steps = [...piSteps, ...adminAccessSteps]
  } else {
    steps = piSteps
  }
  const transformStringToArray = (data, dtype) => {
    if (!data) {
      return [];
    }
    if (dtype === 'fax') {
      let temp = [];
      if (Array.isArray(data)) {
        temp = [...temp, ...data.map(((item) => toString(item)))]
      } else if (typeof data === 'number') {
        temp.push(data.toString())
      } else {
        temp.push(toString(data))
      }
      return temp
    }

    let temp = [];
    if (Array.isArray(data)) {
      temp = [...temp, ...data]
    } else {
      temp.push(data)
    }
    return temp
  }

  const [current, setCurrent] = useState(0);
  const next = () => {
    if (countryForNpiNumber === 'United States' && warningFlag) {
      if ('npi' in principalInvestigator) {
        if (flag !== 'fromEditPI') {
          if (principalInvestigator.npi === '') {
            setAlertModalForNpiID(true)
            setCurrent(current);
            setJumpToPage(current + 1)
            setFlagForApiCall('next')
          } else {
            setFlagForApiCall('')
            setAlertModalForNpiID(false)
            setCurrent(current + 1);
            setAddCenter(true)
            setAddOtherCenter(true)
            dispatch(getautosuggestionResetAction())
          }
        } else if (principalInvestigator.npi.value === '') {
          setAlertModalForNpiID(true)
          setCurrent(current);
          setJumpToPage(current + 1)
          setFlagForApiCall('next')
        } else {
          setFlagForApiCall('')
          setAlertModalForNpiID(false)
          setCurrent(current + 1);
          setAddCenter(true)
          setAddOtherCenter(true)
          dispatch(getautosuggestionResetAction())
        }
      } else {
        setAlertModalForNpiID(true)
        setCurrent(current);
        setJumpToPage(current + 1)
        setFlagForApiCall('next')
      }
    } else {
      setFlagForApiCall('')
      setAlertModalForNpiID(false)
      setCurrent(current + 1);
      setAddCenter(true)
      setAddOtherCenter(true)
      dispatch(getautosuggestionResetAction())
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setAddCenter(true)
    setAddOtherCenter(true)
  };

  const isDisable = () => {
    if (isFormValidated) {
      return true;
    }

    if (hasPermissionForAction('edit_sims_id') && flag === 'fromEditPI' && !isSimsIdVerified) {
      return true
    }
    const firstStep = !configRequired[0]?.every((itm) => {
      if (Array.isArray(principalInvestigator[itm])) {
        return principalInvestigator[itm].length > 0
      }
      if (flag === 'fromEditPI' && itm === 'primary_organization_id') {
        return principalInvestigator.primary_organization_id.value !== ''
      }
      if (flag === 'fromEditPI') {
        if (Array.isArray(principalInvestigator[itm].value)) {
          return ((principalInvestigator[itm].value).length > 0)
        }
        if (principalInvestigator[itm].value === '') {
          return false
        }
        return true
      }
      return !!principalInvestigator[itm]
    })

    const thirdCheck = configRequired[2]?.every((itm) => {
      if (principalInvestigator[itm] || typeof principalInvestigator[itm] === 'object') {
        if (principalInvestigator[itm].value.length === 0 || principalInvestigator[itm].value.length === 10) {
          return false
        }
        return true
      }
      return false
    })

    if (current === 0) {
      return firstStep || thirdCheck
    }
    return false;
  }
  const onResetClick = () => {
    if (flag === 'fromEditPI') {
      switch (current) {
        case 0:
          setPrincipalInvestigator(defaultPi)
          principalInvestigatorForm.resetFields()
          break;
        case 1:
          setCenter(defaultCD)
          centerForm.resetFields();
          break;
        case 2:
          setresearchInterest(profile_responses);
          break;
        case 4:
          setComplianceIssues(defaultCompliance.compliances || []);
          setCddData([])
          break;
        default:
      }
    } else {
      switch (current) {
        case 0:
          setPrincipalInvestigator({ primaryCenter: '', other_centers: [] })
          principalInvestigatorForm.resetFields();
          break;
        case 1:
          setCenter({})
          centerForm.resetFields();
          break;
        case 2:
          setresearchInterest({});
          break;
        case 4:
          setComplianceIssues(defaultCompliance.compliances || []);
          break;
        default:
      }
    }
  }
  const onStepsChange = (value) => {
    if (countryForNpiNumber === 'United States' && warningFlag) {
      if ('npi' in principalInvestigator) {
        if (flag !== 'fromEditPI') {
          if (principalInvestigator.npi === '') {
            setAlertModalForNpiID(true)
            setCurrent(current);
            setJumpToPage(value)
            setFlagForApiCall('steps')
          } else {
            setCurrent(value);
            setFlagForApiCall('')
          }
        } else if (principalInvestigator.npi.value === '') {
          setAlertModalForNpiID(true)
          setCurrent(current);
          setJumpToPage(value)
          setFlagForApiCall('steps')
        } else {
          setCurrent(value);
          setFlagForApiCall('')
        }
      } else {
        setAlertModalForNpiID(true)
        setCurrent(current);
        setJumpToPage(value)
        setFlagForApiCall('steps')
      }
    } else {
      setCurrent(value);
      setFlagForApiCall('')
    }
  };

  const onExistPiYesClick = () => {
    const final = {
      ...principalInvestigator, ...center, ...researchInterest,
    }
    if (!setDefaultValues) {
      const getProfRespData = (resp) => {
        const responses = [];
        const finalKeys = Object.keys(final);
        finalKeys.forEach((key) => {
          if (!isNaN(parseInt(key, 10))) {
            responses.push({
              question_id: parseInt(key, 10),
              int_response: [
              ],
              text_response: [
                resp[key] || '',
              ],
            })
          }
        })
        return responses
      }
      const temp = {
        name: final.piName.concat(' ', final.piMiddleName).concat(' ', final.piLastName),
        pre_award_survey_outreach: final?.pre_award_survey_outreach === undefined ? 'Yes' : final?.pre_award_survey_outreach,
        recent_site_id: final?.primary_center?.key,
        email: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
        primary_email: final.email,
        survey_email: transformStringToArray(final?.surveyEmail),
        other_email: final?.other_email?.split(/[,;]+/),
        phone_number: transformStringToArray(final?.telephoneNo),
        therapeutic_interest: final.therapeutic_interest,
        specialties: final?.specialties,
        primary_organization_id: final?.primary_organization_id,
        title: final.titleName,
        first_name: final.piName,
        last_name: final.piLastName,
        middle_name: final.piMiddleName,
        mobile_number: transformStringToArray(final?.mobileNo),
        // fax: transformStringToArray(final.faxNo, 'fax'),
        fax: final?.faxNo?.split(','),
        secondary_specialties: transformStringToArray(final.secondary_specialties),
        cv_link: '',
        gcp_certificate_link: '',
        year_of_birth: final?.year_of_birth,
        gender: final.gender,
        ethnicity: final.ethnicity,
        languages_spoken: final.languages_spoken,
        type: 'PI',
        profile_responses: getProfRespData(final),
        site_ids: final?.other_centers?.map((itm) => itm.key),
        last_onsite_visit_date: parseInt(new Date(final.last_onsite_visit_date).getTime() / 1000, 10) || 0,
        npi: final.npi ? final.npi.toString() : '',
        is_head_of_department: final.is_head_of_department,
        name_of_department: final.name_of_department,
        consent_type: final.consent_type,
        consent_text: final.consent_text,
        address: {
          address_line1: final.address_line1,
          address_line2: final.address_line2,
          city: final.city,
          state: final.state,
          country: final.country,
          zip: final.zip ? final.zip.toString() : '',
        },
        alliance_type: final.alliance_type,
        alliance_member_id: final.alliance_member_id,
        compliances: getComplicanceData(),
        cdd_data: cddData.length && cddData[0]?.status !== '' ? cddData : [],
        medical_license_data: medicalLicense,
      }
      if (analysisId !== '' && type === 'UploadSponsor') {
        dispatch(createPiAction({
          params: {
            project_id: analysisId,
            force_update: true,
            duplicate_update: true,
            sponsor_meta_iid: piObj.innoplexus_id,
            is_added_by_lcor: viewedFile?.list_source === 'LCOR' ? true : undefined,
            is_added_by_sponsor: viewedFile?.list_source === 'Sponsor' ? true : undefined,
          },
          body: {
            ...temp,
            file_name: viewedFile?.file_name,
          },
        }))
      } else if (analysisId) {
        dispatch(createPiAction({
          params: {
            project_id: analysisId,
            force_update: true,
            duplicate_update: true,
            is_cfl: adminType.includes('cfl_access') ? true : undefined,
            is_lcor: adminType.includes('lcor_access') ? true : undefined,
          },
          body: temp,
        }))
      } else {
        dispatch(createPiAction({
          params: {
            force_update: true,
            duplicate_update: true,
          },
          body: temp,
        }))
      }
    } else {
      const getProfRespData = () => {
        const pr = [];
        const pr_all = defaultValues?.data?.profile_responses;
        const finalResp = { ...researchInterest }

        Object.keys(finalResp).forEach((k) => {
          const temp = {
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: [''],
              new: [finalResp[k]],
              flag: true,
            },
          };
          const index = pr_all.findIndex(
            (item) => item.question_id.toString() === k.toString(),
          );
          if (index < 0) {
            pr.push(temp);
          } else {
            pr.push({
              question_id: {
                old: Number(k),
                new: Number(k),
                flag: true,
              },
              text_response: {
                old: pr_all[index].response,
                new: [final[k]],
                flag: true,
              },
            });
          }
        });
        return pr
      }
      const updateTemp = {
        name: {
          old: defaultPi.piName.value,
          new: final.piName.value.concat(' ', final.piMiddleName.value).concat(' ', final.piLastName.value),
          flag: final.piName.flag || final.piMiddleName.flag || final.piLastName.flag,
        },
        email: {
          old: defaultPi?.other_email ? transformStringToArray(`${defaultPi?.email}, ${defaultPi?.other_email}`) : transformStringToArray(`${defaultPi?.email}`),
          new: final?.other_email ? transformStringToArray(`${final?.email}, ${final?.other_email}`) : transformStringToArray(`${final?.email}`),
          flag: final.email.flag,
        },
        other_email: {
          old: defaultPi?.other_email?.value?.split(/[,;]+/),
          new: final?.other_email?.value?.split(/[,;]+/),
          flag: final.other_email.flag,
        },
        survey_email: {
          old: transformStringToArray(defaultPi?.surveyEmail?.value),
          new: transformStringToArray(final?.surveyEmail?.value),
          flag: final.surveyEmail.flag,
        },
        primary_email: {
          old: defaultPi.email.value,
          new: final.email.value,
          flag: final.email.flag,
        },
        phone_number: {
          old: transformStringToArray(defaultPi.telephoneNo.value),
          new: transformStringToArray(final?.telephoneNo.value),
          flag: final.telephoneNo.flag,
        },
        therapeutic_interest: {
          old: defaultPi.therapeutic_interest.value,
          new: final.therapeutic_interest.value,
          flag: final.therapeutic_interest.flag,
        },
        pre_award_survey_outreach: {
          old: defaultPi.pre_award_survey_outreach.value,
          new: final.pre_award_survey_outreach.value,
          flag: final.pre_award_survey_outreach.flag,
        },
        address_line1: {
          old: '',
          new: '',
          flag: false,
        },
        address_line2: {
          old: '',
          new: '',
          flag: false,
        },
        city: {
          old: '',
          new: '',
          flag: false,
        },
        state: {
          old: '',
          new: '',
          flag: false,
        },
        country: {
          old: '',
          new: '',
          flag: false,
        },
        zip: {
          old: '',
          new: '',
          flag: false,
        },
        specialties: {
          old: defaultPi?.specialties.value,
          new: final?.specialties.value,
          flag: final.specialties.flag,
        },
        primary_organization_id: {
          old: defaultPi?.primary_organization_id?.value,
          new: final.primary_organization_id.value,
          flag: final.primary_organization_id.flag,
        },
        title: {
          old: defaultPi.titleName.value,
          new: final.titleName.value,
          flag: final.titleName.flag,
        },
        first_name: {
          old: defaultPi.piName.value,
          new: final.piName.value,
          flag: final.piName.flag,
        },
        middle_name: {
          old: defaultPi.piMiddleName.value,
          new: final.piMiddleName.value,
          flag: final.piMiddleName.flag,
        },
        last_name: {
          old: defaultPi.piLastName.value,
          new: final.piLastName.value,
          flag: final.piLastName.flag,
        },
        mobile_number: {
          old: transformStringToArray(defaultPi.mobileNo.value),
          new: transformStringToArray(final.mobileNo.value),
          flag: final.mobileNo.flag,
        },
        fax: {
          // old: transformStringToArray(defaultPi.faxNo.value, 'fax'),
          // new: transformStringToArray(final.faxNo.value, 'fax'),
          old: defaultPi?.faxNo?.value?.split(','),
          new: final?.faxNo?.value?.split(','),
          flag: final.faxNo.flag,
        },
        secondary_specialties: {
          old: transformStringToArray(defaultPi.secondary_specialties.value),
          new: transformStringToArray(final.secondary_specialties.value),
          flag: final.secondary_specialties.flag,
        },
        year_of_birth: {
          old: defaultCD.year_of_birth.value || '',
          new: final.year_of_birth.value || '',
          flag: final.year_of_birth.flag,
        },
        gender: {
          old: defaultCD.gender.value || '',
          new: final.gender.value || '',
          flag: final.gender.flag,
        },
        ethnicity: {
          old: defaultCD.ethnicity.value || '',
          new: final.ethnicity.value || '',
          flag: final.ethnicity.flag,
        },
        languages_spoken: {
          old: defaultCD.languages_spoken.value || [],
          new: final.languages_spoken.value || [],
          flag: final.languages_spoken.flag,
        },
        veeva_ready_flag_updated: {
          old: defaultPi?.veeva_ready_flag_updated.value,
          new: final.veeva_ready_flag_updated.value,
          flag: final.veeva_ready_flag_updated.flag,
        },
        profile_responses: getProfRespData(),
        site_ids: {
          old: defaultPi?.other_centers?.value?.map((itm) => { return itm.key }),
          new: final?.other_centers?.value?.map((itm) => itm.key),
          flag: final.other_centers.flag,
        },
        last_onsite_visit_date: {
          old: defaultPi.last_onsite_visit_date_ts.value || 0,
          new: getFinalTimeStamp(final.last_onsite_visit_date.value) || 0,
          flag: final.last_onsite_visit_date.flag,
        },
        npi: {
          old: defaultPi.npi.value,
          new: final.npi.value,
          flag: final.npi.flag,
        },
        is_head_of_department: {
          old: defaultPi.is_head_of_department.value,
          new: final.is_head_of_department.value,
          flag: final.is_head_of_department.flag,
        },
        name_of_department: {
          old: defaultPi.name_of_department.value,
          new: final.name_of_department.value,
          flag: final.name_of_department.flag,
        },
        alliance_type: {
          old: defaultPi.alliance_type.value,
          new: final.alliance_type.value.value || final.alliance_type.value,
          flag: final.alliance_type.flag,
        },
        alliance_member_id: {
          old: defaultPi.alliance_member_id.value,
          new: final.alliance_member_id.value.value || final.alliance_member_id.value,
          flag: final.alliance_member_id.flag,
        },
        compliances: complianceIssues?.map((itm) => {
          return {
            no_trial_participation_flag: {
              old: '',
              new: itm.no_trial_participation_flag,
              flag: true,
            },
            fraud_misconduct_code: {
              old: '',
              new: itm.fraud_misconduct_code,
              flag: true,
            },
            compliance_issue_desc: {
              old: '',
              new: itm.compliance_issue_desc,
              flag: true,
            },
            compliance_issue_comment: {
              old: '',
              new: itm.compliance_issue_comment,
              flag: true,
            },
            compliance_issue_raised_dt: {
              old: '',
              new: itm.compliance_issue_raised_dt,
              flag: true,
            },
            compliance_issue_closed_dt: {
              old: '',
              new: itm.compliance_issue_closed_dt,
              flag: true,
            },
            review_status: {
              old: '',
              new: itm.review_status,
              flag: true,
            },
            valid_flag: {
              old: undefined,
              new: itm.valid_flag,
              flag: true,
            },
            review_date: {
              old: '',
              new: itm.review_date,
              flag: true,
            },
            reviewer_name: {
              old: '',
              new: itm.reviewer_name,
              flag: true,
            },
            reevaluation_comment: {
              old: '',
              new: itm.reevaluation_comment,
              flag: true,
            },
            delete_flag: {
              old: false,
              new: itm.delete_flag,
              flag: true,
            },
            unique_id: {
              old: '',
              new: itm.unique_id,
              flag: true,
            },
          }
        }),
        cdd_data: cddData?.map((itm) => {
          return {
            status: {
              old: '',
              new: itm.status,
              flag: true,
            },
            prevention_flag: {
              old: '',
              new: itm.prevention_flag,
              flag: true,
            },
            date: {
              old: '',
              new: itm.date,
              flag: true,
            },
            comment: {
              old: '',
              new: itm.comment,
              flag: true,
            },
            // unique_id: {
            //   old: '',
            //   new: itm.unique_id,
            //   flag: true,
            // },
          }
        }),
        medical_license_data: getMedicalLicenseDataEdit(),
      }
      dispatch(updatePiAction({
        params: {
          pi_id: defaultValues?.data?.id,
          direct_edit: true,
          duplicate_update: true,
        },
        body: updateTemp,
      }))
    }
    const params = {
      pi_id: defaultValues?.data?.id,
    }
    setAnalytics('editInvestigators', 'data', params)
  }

  const onExistPiNoClick = () => {
    if (flag === 'fromEditPI') {
      setEditModal(false)
    } else {
      setModal(false)
    }
    setAlertModal(false)
    window.location.reload()
  }

  const onProceedBtn = () => {
    if (flagForApiCall === 'next' || flagForApiCall === 'steps') {
      setCurrent(jumpToPage)
      setWarningFlag(false)
      setAlertModalForNpiID(false);
      setFlagForApiCall('')
    } else {
      onSubmitClick()
      setWarningFlag(false)
      setAlertModalForNpiID(false);
      setFlagForApiCall('')
    }
  }

  return (
    <div className="header-wrap">
      <Loader loading={createPiResponse.loading || updatePiResponse.loading}>
        <Form.Provider
          onFormChange={(name, { forms }) => {
            if (Object.keys(forms).length !== 0) {
              const validated = Object.values(forms).every((form) => {
                return form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
              })
              setIsFormValidated(validated)
            } else {
              setIsFormValidated(false)
            }
          }}
        >
          <Steps current={current} onChange={onStepsChange}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} disabled={isDisable()} />
            ))}
          </Steps>
          <div
            className={current === 4 ? 'steps-content compliance' : 'steps-content'}
          >{steps[current].content}
          </div>
          <div className="steps-action">
            <Button disabled={current === 3} onClick={onResetClick}>Reset</Button>
            <Button type="primary" onClick={onDoneClick} style={{ marginRight: '10px' }} disabled={isDisable()}>
              Save and exit
            </Button>
            {current > 0 && (
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
                type="primary"
              >
                Previous
              </Button>
            )}
            {/* {current === steps.length - 1 && (
              <Button type="primary" onClick={onDoneClick} disabled={isDisable()}>
                Done
              </Button>
            )} */}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next} disabled={isDisable()}>
                Next
              </Button>
            )}
          </div>
        </Form.Provider>

        {alertModal && (
          <Modal
            title="PI probably exists in LandSCAPE"
            open={alertModal}
            onCancel={() => setAlertModal(false)}
            className="modal-wrapper add-investigator-modal mark-valid-invalid-pi-modal"
            maskClosable={false}
            destroyOnClose
            footer={null}
          >
            Another PI exists in LandSCAPE with same name. Do you still want to continue with your changes?
            <Button onClick={onExistPiYesClick}> Yes </Button>
            <Button onClick={onExistPiNoClick}> No </Button>
          </Modal>
        )}
        {MadnatoryFieldsMissingModal.isOpen && (
          <Modal
            title="Mandatory fields not provided"
            cancelButtonProps={{ style: { display: 'none' } }}
            visible={MadnatoryFieldsMissingModal.isOpen}
            onOk={() => {
              setCurrent(MadnatoryFieldsMissingModal.step - 1);
              setMadnatoryFieldsMissingModal({ isOpen: false, step: -1 })
            }}
            onCancel={() => setMadnatoryFieldsMissingModal({ isOpen: false, step: -1 })}
            okText={`goto step ${MadnatoryFieldsMissingModal.step}`}
          >
            <div className="error-msg-create-pi">
              Unable to proceed, there are few missing mandatory field on step {MadnatoryFieldsMissingModal.step}.
            </div>
          </Modal>
        )}
        {
          alertModalForNpiID && (
            <Modal
              style={{ marginTop: '81px', width: '100%' }}
              title="Confirmation"
              open={alertModalForNpiID}
              className="del-modal-wrapper conf-modal-wrapper"
              onCancel={() => {
                setAlertModalForNpiID(false);
                setWarningFlag(true)
                setFlagForApiCall('')
              }}
              destroyOnClose
              footer={null}
            >
              <div className="conf-desc"> You have not provided NPI, are you sure you want to proceed?</div>
              <div className="footer-btn">
                <Button
                  className="delete-button"
                  type="primary"
                  onClick={() => onProceedBtn()}
                > Proceed
                </Button>
              </div>
            </Modal>
          )
        }
      </Loader>

    </div>
  )
}
